import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
    getTimes: createCancelTokenHandler('getTimes')
}


export function getTimes (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

    return axios({
        url: `v1/admin/club/times${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getTimes'].handleRequestCancellation().token
    })
}


export function generateTime (force = false) {
    return axios({
        url: `v1/admin/club/times/generate${force ? '/force' : ''}`,
        method: 'post'
    })
}
